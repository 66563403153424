@import "tailwindcss/base";
@import "tailwindcss/components";
@import "./Presentation/**/*.pcss";
@import "tailwindcss/utilities";

@layer base {
  :root {
    --color-light: 0 173 238;
    --color-dark: 38 34 76;
    --content-padding: theme("spacing.4");
    --content-max-width: theme("screens.xl");
  }
}

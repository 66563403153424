@import "@splidejs/splide/dist/css/splide-core.min.css";
@import "@splidejs/splide/dist/css/themes/splide-default.min.css";
@import "@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css";

.splide__pagination__page.is-active {
  background: rgb(var(--color-dark));
}

.splide__slide {
  & > figure {
    width: 100%;
    margin: 0;

    & > img {
      width: 100%;
      height: auto;
    }
  }
}
